<template>
  <div class="sing_up_container">
    <div class="signup__buttons" v-if="step === 0">
      <h2 class="signup__title">{{ $t('Registration') }}</h2>

      <el-button @click="changeStep(roleEnum.ROLE_STUDENT)">{{ $t('i_need_a_job') }}</el-button>
      <el-button @click="changeStep(roleEnum.ROLE_COMPANY)">{{ $t('i_need_employees') }}</el-button>
      <el-button @click="changeStep(roleEnum.ROLE_EDUCATION)">{{ $t('for_educational_institutions') }}</el-button>
    </div>

    <div
      v-if="step === 1 && role === roleEnum.ROLE_STUDENT"
      class="sing_up_form"
    >
      <div class="sing_up_form_container">
        <div class="sing_up">
          <div class="sing_up_former">
            <div class="sing_up_label">{{ $t('Registration') }}</div>
            <div class="sing_up_inputs">
              <div :class="[surname.length > 0 ? activeClass : '', basicClass]">
                <input
                  class="sing_up_email"
                  v-model="surname"
                  :placeholder="$t('Surname')"
                  @blur="$v.surname.$touch()"
                />
              </div>
              <span
                v-if="$v.surname.$dirty && $v.surname.$invalid"
                class="text-danger"
              >
                {{$t('Last_name_required') }}!
              </span>

              <div :class="[name.length > 0 ? activeClass : '', basicClass]">
                <input
                  class="sing_up_email"
                  v-model="name"
                  :placeholder="$t('Name')"
                  @blur="$v.name.$touch()"
                />
              </div>
              <span
                v-if="$v.name.$dirty && $v.name.$invalid"
                class="text-danger"
              >
                {{ $t('Name_required') }}!
              </span>

              <div :class="[email.length > 0 ? activeClass : '', basicClass]">
                <input
                  class="sing_up_email"
                  v-model="email"
                  :placeholder="$t('Email')"
                  @blur="$v.email.$touch()"
                />
              </div>
              <span
                v-if="$v.email.$dirty && $v.email.$invalid"
                class="text-danger"
              >
                {{$t('Enter_valid_email') }}!
              </span>

              <div :class="[cell_phone.length > 0 ? activeClass : '', basicClass]">
                <input
                  class="sing_up_email"
                  v-model="cell_phone"
                  placeholder="+7 (7XX)–XXX–XXXX"
                  v-mask="'+7 (7##)-###-####'"
                  @input="enforcePhoneFormat"
                  @blur="$v.cell_phone.$touch()"
                />
              </div>
              <span
                v-if="$v.cell_phone.$dirty && $v.cell_phone.$invalid"
                class="text-danger"
              >
                {{ $t('Please_enter_a_valid_mobile_phone_number') }}!
              </span>

              <select
                class="sing_up_email"
                :class="[notification_language.length > 0 ? activeNotificationClass : '', basicNotificationClass]"
                v-model="notification_language"
              >
                <option
                  disabled
                  :label="$t('Choose_language')">
                </option>
                <option
                  key="kk"
                  :label="$t('Kazakh')"
                  value="kz">
                </option>
                <option
                  key="ru"
                  :label="$t('Russian')"
                  value="ru">
                </option>
                <option
                  key="en"
                  :label="$t('English')"
                  value="en">
                </option>
              </select>
              <span
                v-if="$v.notification_language.$dirty && $v.notification_language.$invalid"
                class="text-danger"
              >
                {{ $t('Choose_language') }}!
              </span>

              <div :class="[passwordSignUp.length > 0 ? activePassClass : '', basicPassClass]">
                <input
                  :type="showPasswordSignUp ? 'text' : 'password'"
                  v-model="passwordSignUp"
                  class="input_pass_new"
                  :placeholder="$t('Password')"
                  @blur="$v.passwordSignUp.$touch()"
                />
                <div class="input_pass_new_open" v-show="showPasswordSignUp" @click="showPasswordSignUp = false"/>
                <div class="input_pass_new_close" v-show="!showPasswordSignUp" @click="showPasswordSignUp = true"/>
              </div>
              <span
                v-if="$v.passwordSignUp.$dirty && $v.passwordSignUp.$invalid"
                class="text-danger"
              >
                {{ $t('Password_is_required!_At_least_8_characters') }}!
              </span>
              <div :class="[passwordNewSignUp.length > 0 ? activePassClass : '', basicPassClass]">
                <input
                  :type="showPasswordNewSignUp ? 'text' : 'password'"
                  v-model="passwordNewSignUp"
                  class="input_pass_new"
                  :placeholder="$t('Repeat_password')"
                  @blur="$v.passwordSignUp.$touch()"
                />
                <div class="input_pass_new_open" v-show="showPasswordNewSignUp" @click="showPasswordNewSignUp = false"/>
                <div
                  class="input_pass_new_close"
                  v-show="!showPasswordNewSignUp"
                  @click="showPasswordNewSignUp = true"
                />
              </div>
              <span
                v-if="passwordSignUp !== passwordNewSignUp"
                class="text-danger"
              >
                {{ $t('Passwords_must_match') }}!
              </span>

            </div>
            <div class="sing_up_agreement">
              <input
                type="checkbox"
                v-model="checkBoxAgreement"
                @blur="$v.checkBoxAgreement.$touch()"
              />
              {{ $t('I_agree_to_the_Terms_of_Use') }}
              <span
                v-if="$v.checkBoxAgreement.$dirty && $v.checkBoxAgreement.$invalid"
                class="text-danger"
              >
                {{ $t('Agreement') }}!
              </span>
            </div>
            <button class="sing_up_btn_next" :disabled="!showBtn()" @click="signUp">
              <span>{{ $t('Further') }}</span>
            </button>
            <div class="sing_up_on_main">{{ $t('Have_account') }}?&nbsp;
              <router-link to="/signin">{{ $t('Login') }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="step === 1 && (role === roleEnum.ROLE_COMPANY || role === roleEnum.ROLE_EDUCATION)"
      class="sing_up_form"
    >
      <div class="sing_up_form_container">
        <div class="sing_up">
          <div class="sing_up_former">
            <div class="sing_up_label">{{ $t('Registration') }}</div>
            <div class="sing_up_inputs">
              <div :class="[surname.length > 0 ? activeClass : '', basicClass]">
                <input
                  class="sing_up_email"
                  v-model="surname"
                  :placeholder="$t('Surname')"
                  @blur="$v.surname.$touch()"
                />
              </div>
              <span
                v-if="$v.surname.$dirty && $v.surname.$invalid"
                class="text-danger"
              >
                {{$t('Last_name_required') }}!
              </span>

              <div :class="[name.length > 0 ? activeClass : '', basicClass]">
                <input
                  class="sing_up_email"
                  v-model="name"
                  :placeholder="$t('Name')"
                  @blur="$v.name.$touch()"
                />
              </div>
              <span
                v-if="$v.name.$dirty && $v.name.$invalid"
                class="text-danger"
              >
                {{ $t('Name_required') }}!
              </span>

              <div :class="[email.length > 0 ? activeClass : '', basicClass]">
                <input
                  class="sing_up_email"
                  v-model="email"
                  :placeholder="$t('Email')"
                  @blur="$v.email.$touch()"
                />
              </div>
              <span
                v-if="$v.email.$dirty && $v.email.$invalid"
                class="text-danger"
              >
                {{$t('Enter_valid_email') }}!
              </span>

              <div :class="[cell_phone.length > 0 ? activeClass : '', basicClass]">
                <input
                  class="sing_up_email"
                  v-model="cell_phone"
                  placeholder="+7 (7XX)–XXX–XXXX"
                  v-mask="'+7 (7##)-###-####'"
                  @input="enforcePhoneFormat"
                  @blur="$v.cell_phone.$touch()"
                />
              </div>
              <span
                v-if="$v.cell_phone.$dirty && $v.cell_phone.$invalid"
                class="text-danger"
              >
                {{ $t('Please_enter_a_valid_mobile_phone_number') }}!
              </span>

              <div :class="[company_name.length > 0 ? activeClass : '', basicClass]">
                <input
                  class="sing_up_email"
                  v-model="company_name"
                  :placeholder="$t('company_name')"
                  @blur="$v.company_name.$touch()"
                />
              </div>
              <span
                v-if="$v.company_name.$dirty && $v.company_name.$invalid"
                class="text-danger"
              >
                {{ $t('company_name_is_required') }}
              </span>

              <div :class="[passwordSignUp.length > 0 ? activePassClass : '', basicPassClass]">
                <input
                  :type="showPasswordSignUp ? 'text' : 'password'"
                  v-model="passwordSignUp"
                  class="input_pass_new"
                  :placeholder="$t('Password')"
                  @blur="$v.passwordSignUp.$touch()"
                />
                <div class="input_pass_new_open" v-show="showPasswordSignUp" @click="showPasswordSignUp = false"/>
                <div class="input_pass_new_close" v-show="!showPasswordSignUp" @click="showPasswordSignUp = true"/>
              </div>
              <span
                v-if="$v.passwordSignUp.$dirty && $v.passwordSignUp.$invalid"
                class="text-danger"
              >
                {{ $t('Password_is_required!_At_least_8_characters') }}!
              </span>

              <div :class="[passwordNewSignUp.length > 0 ? activePassClass : '', basicPassClass]">
                <input
                  :type="showPasswordNewSignUp ? 'text' : 'password'"
                  v-model="passwordNewSignUp"
                  class="input_pass_new"
                  :placeholder="$t('Repeat_password')"
                  @blur="$v.passwordSignUp.$touch()"
                />
                <div class="input_pass_new_open" v-show="showPasswordNewSignUp" @click="showPasswordNewSignUp = false"/>
                <div
                  class="input_pass_new_close"
                  v-show="!showPasswordNewSignUp"
                  @click="showPasswordNewSignUp = true"
                />
              </div>
              <span
                v-if="passwordSignUp !== passwordNewSignUp"
                class="text-danger"
              >
                {{ $t('Passwords_must_match') }}!
              </span>

            </div>

            <div class="sing_up_agreement">
              <input
                type="checkbox"
                v-model="checkBoxAgreement"
                @blur="$v.checkBoxAgreement.$touch()"
              />
              {{ $t('I_agree_to_the_Terms_of_Use') }}
              <span
                v-if="$v.checkBoxAgreement.$dirty && $v.checkBoxAgreement.$invalid"
                class="text-danger"
              >
                {{ $t('Agreement') }}!
              </span>
            </div>

            <button
              class="sing_up_btn_next"
              :disabled="!enableCompanyBtn()"
              @click="signUp"
            >
              <span>{{ $t('Further') }}</span>
            </button>

            <div class="sing_up_on_main">{{ $t('Have_account') }}?&nbsp;
              <router-link to="/signin">{{ $t('Login') }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="showModal" class="sign_up_modal">
      <div class="sign_up_modal_back" @click="showModal = false" />
      <div class="sign_up_modal_front">
        <div class="sign_up_modal_text">
          <div class="sign_up_confirm_mail">{{ $t('To_continue_registration,_you_must_confirm_your_email') }}</div>
          <div class="sign_up_please_confirm">{{ $t('Please_check_your_mail') }}</div>
        </div>
        <div class="sign_up_not_receive">{{ $t('I_did_not_receive_letter') }}</div>
        <a href="/signin" class="sign_up_close_form" @click="showModal = false"></a>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import VueMask from 'v-mask'
import {
  required,
  minLength,
  email
} from "vuelidate/lib/validators";
import roleEnum from "@/enums/user/roleEnum";

export default {
  name: 'SignUp',
  computed: {
    roleEnum() {
      return roleEnum
    }
  },
  beforeCreate() {
    Vue.use(VueMask);
  },
  data() {
    return {
      step: 0,

      role: null,
      surname: '',
      name: '',
      company_name: '',
      email: '',
      cell_phone: '',
      notification_language: '',
      passwordSignUp: '',
      passwordNewSignUp: '',

      basicClass: 'sing_up_input_container',
      activeClass: 'sing_up_input_container_active',
      basicPassClass: 'input_pass_container_signup',
      activePassClass: 'input_pass_container_signup_active',
      basicNotificationClass: 'sing_up_notification_container',
      activeNotificationClass: 'sing_up_notification_container_active',
      showPasswordSignUp: false,
      showPasswordNewSignUp: false,
      listLangVisible: false,
      showModal: false,
      notification_language_for_request: '',
      cell_phone_for_request: '',
      checkBoxAgreement: false,
      locale: '',
    }
  },
  methods: {
    chooseLang(lang) {
      this.listLangVisible = false
      this.notification_language = lang
      if (lang === 'Русский') {
        this.notification_language_for_request = 'ru'
      } else if (lang === 'Казахский') {
        this.notification_language_for_request = 'kk'
      } else this.notification_language_for_request = 'en'
    },
    enforcePhoneFormat() {
      //this.$v.phone.$touch();
      let x = this.cell_phone
        .replace(/\D/g, "")
        .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      this.cell_phone_for_request = x[0]
    },
    signUp() {
      const body = {
        last_name: this.surname,
        first_name: this.name,
        email: this.email,
        phone: this.cell_phone_for_request,
        password: this.passwordSignUp,
        password_confirmation: this.passwordNewSignUp,
        role: this.role,
      };

      if (this.role === roleEnum.ROLE_STUDENT) {
        body.notification_language = this.notification_language;
      }

      if (this.role === roleEnum.ROLE_COMPANY || this.role === roleEnum.ROLE_EDUCATION) {
        body.company_name = this.company_name;
      }

      this.$http.post(API_ROOT + `/api/auth/register`, body)
        .then(() => {
          this.showModal = true
        })
        .catch((err) => {
          Vue.toastr({
            message: this.$t('An_error_has_occurred'),
            description: err.body.message ?? this.$('auth_error'),
            type: 'error'
          })
        })
    },

    showBtn() {
      return !!this.surname.length
        && !!this.name.length
        && !!this.email.length
        && this.cell_phone.length > 16
        && !!this.notification_language.length
        && this.passwordSignUp.length > 5
        && this.passwordNewSignUp.length > 5
        && this.passwordSignUp === this.passwordNewSignUp
        && this.checkBoxAgreement
    },
    enableCompanyBtn() {
      return !!this.surname.length
        && !!this.name.length
        && !!this.email.length
        && this.cell_phone.length > 16
        && !!this.company_name.length
        && this.passwordSignUp.length > 5
        && this.passwordNewSignUp.length > 5
        && this.passwordSignUp === this.passwordNewSignUp
        && this.checkBoxAgreement
    },

    notificationBlur() {
      $v.notification_language.$touch()
      this.listLangVisible(false)
    },
    changeStep(role) {
      this.role = role;
      this.step++;
    }
  },
  validations: {
    surname: {
      required,
      minLength: minLength(2)
    },
    name: {
      required,
      minLength: minLength(2)
    },
    email: {
      required,
      email,
    },
    cell_phone: {
      required,
      minLength: minLength(17),
    },
    passwordSignUp: {
      required,
      minLength: minLength(8)
    },
    passwordNewSignUp: {
      required,
      minLength: minLength(8)
    },
    notification_language: {
      required
    },
    company_name: {
      required
    },
    checkBoxAgreement: {
      required
    }
  },
}
</script>

<style lang="scss" scoped>
.signup {
  &__title {
    color: #007BFC;
    font-family: "RoadRadio", sans-serif;
    margin-bottom: 20px;
  }

  &__buttons {
    background-color: #ffffff;
    border-radius: 0 40px 40px 0;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 150px;
    height: 100%;
    width: 50%;
    z-index: 1;

    @media screen and (max-width: 992px) {
      box-shadow: none;
      border-radius: 0;
      padding: 0 20px;
      width: 100%;
    }

    & > .el-button {
      text-align: left;
      font-size: 24px;
      font-weight: 600;
      padding-top: 20px;
      padding-bottom: 20px;
      position: relative;
      max-width: 420px;
      margin: 0;
      width: 100%;

      @media screen and (max-width: 992px) {
        font-size: 16px;
      }

      &:after {
        content: '';
        background-image: url('~@/assets/icons/caret_right.svg');
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        height: 32px;
        width: 32px;
      }

      & + .el-button {
        margin-top: 30px;
      }
    }
  }
}
</style>

